import {
    OnboardingApplicantV1,
    OnboardingApplicationDataV1,
    OnboardingApplicationV1,
} from './onboarding-application-v1';
import {
    OnboardingApplicantV2,
    OnboardingApplicationDataV2,
    OnboardingApplicationV2,
} from './onboarding-application-v2';
import { UnlockApplicant, UnlockApplication, UnlockApplicationData } from './unlock-application';

/**
 * The below ApplicationData types are an attempt to give structure
 * to the JSON data that is saved in the DB. It's by
 * no means exhaustive and you may find discrepencies. The next step
 * to ensuring correct data is validating the data with a library
 * like Zod or Joi.
 *
 * These are also duplicated in the services repo
 *
 */

// List of valid application versions
export enum APPLICATION_VERSION {
    V1 = '1',
    V2 = '2',
}

// This application status represents the onboarding client application status
export enum APPLICATION_FORM_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING_LETTER_OF_EMPLOYMENT = 'PENDING_LETTER_OF_EMPLOYMENT',
    APPROVED = 'APPROVED',
    SUBMITTED = 'SUBMITTED',
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    OTHER = 'OTHER',
    COMPLETED = 'COMPLETED',
}

export enum APPLICANT_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING_LETTER_OF_EMPLOYMENT = 'PENDING_LETTER_OF_EMPLOYMENT',
    APPROVED = 'APPROVED',
    SUBMITTED = 'SUBMITTED',
    CANCELLED = 'CANCELLED',
    DECLINED = 'DECLINED',
    OTHER = 'OTHER',
    COMPLETED = 'COMPLETED',
}

export enum APPLICATION_DOCUMENT_TYPE {
    GOOGLE_DRIVE_PUBLIC_FOLDER = 'GOOGLE_DRIVE_PUBLIC_FOLDER',
    GOOGLE_DRIVE_PRIVATE_FOLDER = 'GOOGLE_DRIVE_PRIVATE_FOLDER',
    IDENTITY_CHECK_REPORT = 'IDENTITY_CHECK_REPORT',
    LETTER_OF_EMPLOYMENT = 'LETTER_OF_EMPLOYMENT',
    APPLICATION_FORM = 'APPLICATION_FORM',
    INVOICE = 'INVOICE',
    OTHER = 'OTHER',
    PRE_AUTHORIZED_DEBIT_AGREEMENT = 'PRE_AUTHORIZED_DEBIT_AGREEMENT',
    OWNER_RESIDENT_AGREEMENT = 'OWNER_RESIDENT_AGREEMENT',
}

export enum APPLICATION_DOCUMENT_STATUS {
    SUBMITTED = 'SUBMITTED',
    AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    OTHER = 'OTHER',
    PENDING = 'PENDING',
}

export enum APPLICATION_DOCUMENT_SOURCE {
    MANUAL = 'MANUAL',
    CERTN = 'CERTN',
    OTHER = 'OTHER',
    HELLOSIGN = 'HELLOSIGN',
    PLAID = 'PLAID',
}

// DB
export interface BaseApplicationDocument {
    id: string;
    user_id: string;
    external_identifier_id: string | null;
    google_drive_url: string | null;
    source: APPLICATION_DOCUMENT_SOURCE;
    status: APPLICATION_DOCUMENT_STATUS;
    type: APPLICATION_DOCUMENT_TYPE;
    created_at: string | null;
    updated_at: string | null;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface ApplicationDocument extends BaseApplicationDocument {
    external_identifier_id: string | '';
    google_drive_url: string | '';
    createdAt: string;
    updatedAt: string;
}

export enum OrgCode {
    KEY = 'key',
    CLOVER = 'clover',
}

export type ApplicationForm = {
    id: string;
    status: APPLICATION_FORM_STATUS;
    building_suite_id: string | null;
    created_at: string; // ISO-8601
    updated_at: string; // ISO-8601
};

export type OnboardingApplication = OnboardingApplicationV1 | OnboardingApplicationV2;

// Type create by joining ApplicationForm and select Applicant fields
export type Application = OnboardingApplication | UnlockApplication;

export type OnboardingApplicationData = OnboardingApplicationDataV1 | OnboardingApplicationDataV2;

export type ApplicationData = UnlockApplicationData | OnboardingApplicationData;

export type OnboardingApplicant = OnboardingApplicantV1 | OnboardingApplicantV2;

export type Applicant = UnlockApplicant | OnboardingApplicant;
